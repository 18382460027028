<template>
  <div class="form">
    <div class="container">
      <div class="flex justify-center py-24" v-if="success">
        <p class="font-bold text-xl color-primary">
          Kollégánk a kiválasztott márkakereskedésből hamarosan felveszi a
          kapcsolatot Veled a megadott csatornán keresztül.
        </p>
      </div>

      <div class="form__wrapper" v-else>
        <div class="form__row">
          <label for="l_name">Név</label>
          <input type="text" v-model="form.name" id="l_name" />
          <div
            v-if="errorsMissing.indexOf('name') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
        </div>

        <div class="form__row">
          <label for="l_email">E-mail cím</label>
          <input type="text" v-model="form.email" id="l_email" />
          <div
            v-if="errorsMissing.indexOf('email') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
          <div
            v-if="errorsFormat.indexOf('email') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Az e-mail címed formátuma hibás. Kérjük, ellenőrizd a
            továbblépéshez!
          </div>
        </div>

        <div class="form__row">
          <label for="l_phone">Telefonszám</label>
          <input type="text" v-model="form.phone" id="l_phone" />
          <div
            v-if="errorsMissing.indexOf('phone') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
        </div>

        <div class="form__row">
          <label for="l_car">Melyik modellt próbálnád ki?</label>
          <select id="l_car" v-model="form.car_id">
            <option value="">Válassz modellt!</option>
            <option v-for="car in cars" :key="car.id" :value="car.id">
              {{ car.title }}
            </option>
          </select>
          <div
            v-if="errorsMissing.indexOf('car_id') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
        </div>

        <div class="form__row">
          <label for="l_location">Melyik helyszínen tesztelnél?</label>
          <select id="l_location" v-model="form.location_id">
            <option value="">Válassz kereskedést!</option>
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
            >
              {{ location.city }} ({{ location.title }})
            </option>
          </select>
          <div
            v-if="errorsMissing.indexOf('location_id') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
        </div>

        <div class="form__row">
          <label for="l_time">Mikor tesztelnél?</label>
          <select id="l_time" v-model="form.time">
            <option value="">Válassz időpontot!</option>
            <option
              v-for="time in currentRange"
              :key="time.key"
              :value="time.key"
            >
              {{ time.value }}
            </option>
          </select>
          <div
            v-if="errorsMissing.indexOf('time') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            Kérjük, pótold a továbblépéshez!
          </div>
        </div>

        <div class="text-sm my-4">
          <p class="mb-3">
            Kérésed elküldésével hozzájárulsz ahhoz, hogy személyes adataid a
            Skoda magyarországi nagykereskedője, a Porsche Hungaria Kft. a
            kérésedben megjelölt márkakereskedésnek továbbítsa, továbbá ahhoz,
            hogy a megjelölt márkakereskedés kérésed teljesítésével kapcsolatban
            személyes adataid kezelje annak érdekében, hogy felvegyük veled a
            kapcsolatot a megadott csatornákon.
          </p>
          <p>A kérésemmel kapcsolatban az alábbi csatornán kereshetnek meg:</p>

          <div class="flex my-2">
            <div class="flex gap-1 items-center mr-3">
              <input
                type="checkbox"
                v-model="form.contact_email"
                id="l_contact_email"
              />
              <label for="l_contact_email">e-mail</label>
            </div>
            <div class="flex gap-1 items-center">
              <input
                type="checkbox"
                v-model="form.contact_phone"
                id="l_contact_phone"
              />
              <label for="l_contact_phone">telefon</label>
            </div>
          </div>

          <div
            v-if="errorsMissing.indexOf('contacts') !== -1"
            class="text-xs text-red-500 mt-1 mb-3"
          >
            Kérjük, legalább az egyik kommunikációs csatornát jelöld meg!
          </div>

          <div class="flex gap-1 items-center">
            <input type="checkbox" v-model="form.accepted" id="l_accepted" />
            <label for="l_accepted">
              Elfogadom az
              <a
                href="https://www.skoda.hu/kapcsolat/impresszum/adatvedelem-es-adatkezeles"
                class="underline"
                target="_blank"
                rel="noopener"
                >adatvédelmi nyilatkozatot</a
              >.
            </label>
          </div>

          <div
            v-if="errorsMissing.indexOf('accepted') !== -1"
            class="text-xs text-red-500 mt-1"
          >
            A továbblépéshez el kell fogadnod a nyilatkozatokat. Kérjük, pótold,
            és már mehetünk is tovább!
          </div>
        </div>

        <div class="mt-1">
          <button type="button" class="button button-primary" @click="submit()">
            Jelentkezés
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format, eachDayOfInterval } from "date-fns";
import { hu } from "date-fns/locale";

export default {
  data() {
    return {
      cars: [],
      locations: [],

      success: false,
      errorsMissing: [],
      errorsFormat: [],

      form: {
        name: "",
        email: "",
        phone: "",
        car_id: "",
        location_id: "",
        time: "",
        contact_email: false,
        contact_phone: false,
        accepted: false,
      },
    };
  },

  mounted() {
    axios.get("/api/cars").then((response) => {
      this.cars = response.data;
    });

    axios.get("/api/locations").then((response) => {
      this.locations = response.data;
    });

    if (window.locationFromUrl) {
      this.form.location_id = window.locationFromUrl.id;
    }
  },

  created() {
    const car = localStorage.getItem("car");
    if (car) {
      this.form.car_id = JSON.parse(car).id;
    }

    const location = localStorage.getItem("location");
    if (location) {
      this.form.location_id = JSON.parse(location).id;
    }

    this.emitter.on("car-set", (car) => {
      this.form.car_id = car;
    });

    this.emitter.on("location-set", (location) => {
      this.form.location_id = location;
    });
  },

  methods: {
    storeCurrent() {
      const currentCar = this.cars.filter(
        (row) => row.id === this.form.car_id
      )[0];
      const currentLocation = this.locations.filter(
        (row) => row.id === this.form.location_id
      )[0];
      localStorage.setItem("car", JSON.stringify(currentCar));
      localStorage.setItem("location", JSON.stringify(currentLocation));
    },

    resetErrors() {
      this.success = false;
      this.errorsMissing = [];
      this.errorsFormat = [];
    },

    submit() {
      this.resetErrors();
      this.storeCurrent();

      axios
        .post("/api/send", this.form, {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-TOKEN": window.csrf_token,
        })
        .then((response) => {
          const data = response.data;

          if (data.success) {
            this.success = true;

            let currentCar = Object.freeze(
              this.cars.filter((row) => row.id === this.form.car_id)[0]
            );
            let currentLocation = Object.freeze(
              this.locations.filter(
                (row) => row.id === this.form.location_id
              )[0]
            );
            let currentDate = format(new Date(this.form.time), "Y-MM-d", {
              locale: hu,
            });

            gtag("event", "generate_lead", {
              modell: `${currentCar.title}`,
              location: `${currentLocation.title} (${currentLocation.api_id})`,
              date: `${currentDate}`,
            });

            fbq("track", "Lead");
          }

          if (!data.success) {
            if (data.errors) {
              this.errorsMissing = data.errors.missing;
              this.errorsFormat = data.errors.format;
            }
          }
        });
    },
  },

  computed: {
    currentRange() {
      let dates = [];
      let currentLocation = this.locations.filter(
        (row) => row.id === this.form.location_id
      )[0];

      if (currentLocation) {
        let location = JSON.parse(JSON.stringify(currentLocation));

        location.dates.forEach((row) => {
          dates.push({
            key: format(new Date(row), "Y-MM-d"),
            value: format(new Date(row), "Y. MMMM d.", { locale: hu }),
          });
        });
      }

      return dates;
    },
  },
};
</script>
