<template>
  <div class="locations" v-if="isLoaded">
    <div class="locations__title">Gyere el és találkozz velünk!</div>

    <ul role="tablist" class="locations__tabs" aria-label="Rendezés">
      <li>
        <button
          type="button"
          class="locations__tab"
          role="tab"
          :aria-selected="tab === 'upcoming'"
          tabindex="0"
          aria-label="Következő események"
          @click="changeTab('upcoming')"
        >
          Következő események
        </button>
      </li>
      <li>
        <button
          type="button"
          class="locations__tab"
          role="tab"
          :aria-selected="tab === 'past'"
          tabindex="1"
          aria-label="Múltbéli események"
          @click="changeTab('past')"
        >
          Múltbéli események
        </button>
      </li>
    </ul>

    <div
      class="locations__grid"
      :class="{ 'is-dimmed': isDimmed }"
      v-if="tab === 'upcoming'"
    >
      <div
        class="locations__item"
        :class="{ 'is-selected': isSelected === location.id }"
        v-for="location in upcomingLocations"
        :key="location.id"
        @click="setLocation(location)"
        @mouseenter="setHovered(location.id)"
        @mouseleave="resetHovered"
      >
        <div class="locations__item__pin"></div>
        <div>
          {{ formatRange(location.date_from, location.date_to) }}<br />
          <b class="uppercase">{{ location.city }}</b>
        </div>
        <div class="px-8">
          <b>{{ location.title }}</b
          ><br />
          {{ location.address }}
        </div>
        <button type="button" class="button button-arrow">Tovább</button>
      </div>
    </div>

    <div class="locations__grid is-slightly-dimmed" v-if="tab === 'past'">
      <div
        class="locations__item"
        :class="{ 'is-selected': isSelected === location.id }"
        v-for="location in pastLocations"
        :key="location.id"
      >
        <div class="locations__item__pin"></div>
        <div>
          {{ formatRange(location.date_from, location.date_to) }}<br />
          <b class="uppercase">{{ location.city }}</b>
        </div>
        <div class="px-8">
          <b>{{ location.title }}</b
          ><br />
          {{ location.address }}
        </div>
        <button type="button" class="button button-arrow">Tovább</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";
import { hu } from "date-fns/locale";

export default {
  data() {
    return {
      tab: "upcoming",
      isLoaded: false,
      isSelected: null,
      isDimmed: false,
      locations: [],
    };
  },

  async mounted() {
    await axios.get("/api/locations").then((response) => {
      this.locations = response.data;
      this.isLoaded = true;

      if (window.locationFromUrl) {
        this.setHovered(window.locationFromUrl.id);
      }
    });
  },

  methods: {
    changeTab(tab) {
      this.tab = tab;
    },

    setLocation(location) {
      this.$emit("locationSelected", location);
    },

    setHovered(id) {
      this.isSelected = id;
      this.isDimmed = true;
    },

    resetHovered() {
      this.isSelected = null;
      this.isDimmed = false;
    },

    formatDate(str) {
      return format(new Date(str), "Y. MMMM d.", { locale: hu });
    },

    formatRange(from, to) {
      let fromDate = format(new Date(from), "Y. MMMM d", { locale: hu });
      let toDate = format(new Date(to), "d", { locale: hu });

      if (new Date(from).getMonth() !== new Date(to).getMonth()) {
        toDate = format(new Date(to), "MMMM d", { locale: hu });
      }

      return `${fromDate}-${toDate}.`;
    },
  },

  computed: {
    upcomingLocations() {
      return this.locations.filter((row) => !row.is_expired);
    },

    pastLocations() {
      return this.locations.filter((row) => row.is_expired);
    },
  },
};
</script>
