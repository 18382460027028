<template>
  <div class="cars" v-if="isLoaded">
    <div class="container">
      <div class="cars__title">
        Próbáld ki sportos modelljeinket országszerte!
      </div>

      <div class="cars__wrapper">
        <Carousel :wrapAround="true" :transition="500" ref="carousel">
          <Slide v-for="car in cars" :key="car.id">
            <div class="cars__item">
              <div class="cars__item__image">
                <img :src="`/img/cars/${car.slug}.png`" :alt="car.title" />
              </div>
              <h2 v-text="car.title"></h2>
              <div v-text="car.content"></div>
              <div
                class="flex flex-col md:flex-row md:justify-center my-4 gap-1 md:gap-4"
              >
                <div v-if="car.urls.configurator" class="block md:inline">
                  <a
                    :href="car.urls.configurator"
                    class="button button-secondary"
                    target="_blank"
                    rel="noopener"
                    >Konfigurálj!</a
                  >
                </div>
                <div v-if="car.urls.web" class="block md:inline">
                  <a
                    :href="car.urls.web"
                    class="button button-secondary"
                    target="_blank"
                    rel="noopener"
                  >
                    <span v-if="car.id === 3 || car.id === 4"
                      >Tudj meg többet az elektromos autózásról!</span
                    >
                    <span v-else>Ismerd meg!</span>
                  </a>
                </div>
                <div v-if="car.urls.video" class="block md:inline">
                  <a
                    :href="car.urls.video"
                    class="button button-secondary"
                    target="_blank"
                    rel="noopener"
                    >Videó</a
                  >
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="button button-primary"
                  @click="selectCar(car)"
                >
                  Kipróbálom
                </button>
              </div>
            </div>
          </Slide>
        </Carousel>

        <button type="button" class="carousel__arrow is-prev" @click="prev()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 39 70"
            class="transform rotate-180"
          >
            <path
              fill-rule="evenodd"
              fill="currentColor"
              d="M38.999,34.999 L38.999,35.0 L3.999,70.0 L0.499,66.499 L32.0,34.999 L0.499,3.499 L3.999,0.0 L39.0,34.999 L38.999,34.999 Z"
            />
          </svg>
        </button>
        <button type="button" class="carousel__arrow is-next" @click="next()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 39 70"
          >
            <path
              fill-rule="evenodd"
              fill="currentColor"
              d="M38.999,34.999 L38.999,35.0 L3.999,70.0 L0.499,66.499 L32.0,34.999 L0.499,3.499 L3.999,0.0 L39.0,34.999 L38.999,34.999 Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Navigation,
    Slide,
  },

  data() {
    return {
      isLoaded: false,
      cars: [],
    };
  },

  async mounted() {
    await axios.get("/api/cars").then((response) => {
      this.cars = response.data;
      this.isLoaded = true;
    });
  },

  methods: {
    selectCar(car) {
      this.$emit("carSelected", car);
    },

    prev() {
      this.$refs.carousel.prev();
    },

    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>
