<script setup>
import Cars from "./components/Cars.vue";
import Form from "./components/Form.vue";
import Hero from "./components/Hero.vue";
import Locations from "./components/Locations.vue";
</script>

<template>
  <main>
    <Hero />

    <div id="section-cars">
      <Cars @car-selected="setCar" />
    </div>

    <div id="section-locations">
      <Locations @location-selected="setLocation" />
    </div>

    <div id="section-form">
      <Form />
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    setCar(car) {
      localStorage.setItem("car", JSON.stringify(car));
      this.emitter.emit("car-set", car.id);

      window.scrollTo({
        top: document.getElementById("section-locations").offsetTop,
        behavior: "smooth",
      });
    },

    setLocation(location) {
      localStorage.setItem("location", JSON.stringify(location));
      this.emitter.emit("location-set", location.id);

      window.scrollTo({
        top: document.getElementById("section-form").offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>
